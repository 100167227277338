<template lang="pug">
div(v-if="field?.uri" v-html="field.uri.value" ref="container")
</template>
<script setup>
  // IMPORT USED FOR STORYBOOK
  import { ref, onMounted } from 'vue'

  const props = defineProps({
    field: { type: Object, required: true },
    class: { type: String, required: false },
  })
  const container = ref(null)

  onMounted(() => {
    if (
      props.field?.uri &&
      container.value?.children?.length > 0 &&
      props.class
    ) {
      props.class.split(' ').forEach((classe) => {
        container.value.children[0].classList.add(classe)
      })
    }
  })
</script>
